<template>
  <div class="wrapper" @click.self="$emit('done')">
    <div class="modal">
      <carousel
        :navigateTo="startIndex"
        :perPage="1"
        :navigationEnabled="true"
        :paginationPadding="0"
      >
        <slide v-for="i in images" :key="i.id">
          <img class="carousel-image" :src="i.media.uri" :alt="i.description" />
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "ImageModal",
  components: { Carousel, Slide },
  props: {
    startIndex: {
      type: Number,
      default: 0
    },
    images: {
      type: Array,
      default: function() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- wrapper ------------------------------
.wrapper {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(#000, 0.7);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// -- modal card ---------------------------
.modal {
  background-color: white;
  max-width: 650px;
  width: 95%;
  border-radius: $radius;
  overflow: hidden;
}

// -- carousel image -----------------------
.carousel-image {
  width: 100%;
  height: auto;
}
</style>

<template>
  <div>
    <!-- -- loader ------------------------- -->
    <loader v-if="loading" fullScreen />

    <!-- -- image modal -------------------- -->
    <image-modal
      v-if="modal"
      :startIndex="startModalIndex"
      :images="images"
      @done="modal = false"
    />

    <!-- -- nav ---------------------------- -->
    <nav-bar />

    <!-- -- image grid ------------------- -->
    <section class="image-grid">
      <div
        v-for="(i, index) in firstFour"
        class="image-grid-item"
        :key="i.id"
        :style="{ backgroundImage: `url('${i.media.uri}')` }"
        @click="handleImageClick(index)"
      />

      <!-- -- indicator ------------------ -->
      <span class="indicator">
        <fa icon="images" />
        {{ images.length }}
      </span>
    </section>

    <div class="container">
      <!-- -- details ---------------------- -->
      <section class="details">
        <!-- -- title ---------------------- -->
        <h1 class="title">
          {{ project.city }} <span>{{ project.adres_line }}</span>
        </h1>

        <hr />

        <!-- -- properties ----------------- -->
        <aside class="properties">
          <div class="pair">
            <span class="key">bouw</span>
            <span class="value">{{ project.project_type }}</span>
          </div>

          <div class="pair">
            <span class="key">object</span>
            <span class="value">{{ project.building_type }}</span>
          </div>

          <div class="pair">
            <span class="key">status</span>
            <span class="value">{{ project.status }}</span>
          </div>

          <div class="pair">
            <span class="key">oppervlakte</span>
            <span class="value">{{ project.area }} m²</span>
          </div>
        </aside>

        <!-- -- description ---------------- -->
        <h2 class="key">beschrijving</h2>
        <section class="description">
          <p class="short" v-if="!project.description">
            {{ project.short }}
          </p>
          <div
            class="content"
            v-if="project.description"
            v-html="project.description"
          ></div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/nav-bar.vue";
import ImageModal from "@/components/image-modal.vue";

import axios from "../plugins/axios";

export default {
  name: "ProjectDetail",
  components: { NavBar, ImageModal },
  data() {
    return {
      loading: false,
      modal: false,
      startModalIndex: 0,
      images: [],
      project: {}
    };
  },

  async mounted() {
    this.loading = true;

    // fetch project
    const id = this.$route.params.id;
    const { data: project } = await axios.get("project/" + id);
    this.project = project;

    // update title
    document.title = `Depaco NV - ${project.city}, ${project.adres_line}`;

    // fetch images
    if (project.images && project.images.length !== 0) {
      const params = ["limit=100", `id[in]=${project.images.join(",")}`];
      const resp = await axios.get("projectmedia?" + params.join("&"));
      this.images = resp.data.results;
    }

    this.loading = false;
  },

  methods: {
    handleImageClick(index) {
      this.startModalIndex = index;
      this.modal = true;
    },
    handleDone() {
      this.modal = false;
    }
  },

  computed: {
    firstFour() {
      const end = this.images.length >= 4 ? 4 : this.images.length;
      return this.images.slice(0, end);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
$aspect-ratio: 1920 / 1080;

// -- def image style ----------------------
img {
  width: 100%;
  height: auto;
}

// -- image grid ---------------------------
.image-grid {
  padding: 1rem;
  position: relative;

  @media (min-width: $medium) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(
      3,
      calc(((100vw / 3) / #{$aspect-ratio}) - 3rem)
    );
    gap: 1rem;
  }

  .image-grid-item {
    width: 100%;
    height: calc((100vw / #{$aspect-ratio}) - 3rem);
    background-size: cover;
    background-position: center;
    cursor: pointer;

    @media (min-width: $medium) {
      height: 100%;

      &:first-child {
        grid-column: 1;
        grid-row: span 3;
      }

      &:not(:first-child) {
        grid-column: 2;
      }
    }

    &:not(:first-child) {
      display: none;

      @media (min-width: $medium) {
        display: block;
      }
    }
  }
}

// -- indicator ----------------------------
.image-grid .indicator {
  background-color: rgba($secondary, 0.4);
  padding: 0.2rem 0.5rem;
  border-radius: $radius;
  color: white;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  @media (min-width: $medium) {
    top: unset;
    bottom: 1.5rem;
  }
}

// -- title --------------------------------
.details h1.title {
  margin: unset;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.5rem;

  span {
    color: $primary;
    font-size: 1rem;
    font-weight: 400;
  }
}

// -- properties ---------------------------
.details .properties {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  row-gap: 1rem;

  .pair {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .pair .key {
    color: $primary;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .pair .value {
    font-size: 0.9rem;
  }
}

// -- description --------------------------
.details h2.key {
  margin: unset;
  padding-top: 1rem;
  color: $primary;
  text-transform: uppercase;
  // font-size: 0.8rem;
  font-weight: 400;
}

.details section.description {
  overflow-x: hidden;
}

// -- hr -----------------------------------
.details hr {
  width: 30%;
  margin: 1rem 0;
  border-top: 1px solid rgba($primary, 0.4);
}
</style>

<template>
  <div>
    <!-- -- nav ---------------------------- -->
    <nav-bar />

    <!-- -- project list ------------------- -->
    <div class="container project-container">
      <!-- -- loading ---------------------- -->
      <loader v-if="loading" fullScreen />

      <!-- -- grid ------------------------- -->
      <section class="project-grid">
        <project-card
          v-for="p in projects"
          :key="p.id"
          :project="p"
          :headerSource="p.headerImage"
        />
      </section>

      <!-- -- pagination ------------------- -->
      <pagination
        class="pagination-nav"
        :current="currentPage"
        :total="totalPages"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/nav-bar.vue";
import ProjectCard from "@/components/project-card.vue";
import Pagination from "vue-sliding-pagination";
import Vue from "vue";

import axios from "../plugins/axios";

export default {
  name: "ProjectList",
  title: "Depaco NV - Projects",
  components: { NavBar, ProjectCard, Pagination },

  data() {
    return {
      projects: [],
      currentPage: 1,
      totalPages: 1,
      loading: false
    };
  },

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      this.loading = true;
      const params = ["limit=12", `page=${this.currentPage - 1}`].join("&");
      const resp = await axios.get("project?" + params);

      // extract data from response
      this.projects = resp.data.results;
      this.totalPages = Math.ceil(resp.data.total / 12);
      await this.appendHeaderImage();

      this.loading = false;
    },
    async appendHeaderImage() {
      // extract header images
      const headerRefs = [];
      for (let p of this.projects) {
        if (!p.images || !p.images[0]) continue;
        headerRefs.push(p.images[0]);
      }

      // make request for images
      const params = ["limit=12", `id[in]=${headerRefs.join(",")}`].join("&");
      const resp = await axios.get("projectmedia?" + params);

      // update project array
      for (let i of resp.data.results) {
        const pIndex = this.projects.findIndex(p => p.id === i.project);
        Vue.set(this.projects, pIndex, {
          ...this.projects[pIndex],
          headerImage: i.media.uri
        });
      }
    },
    async handlePageChange(selectedPage) {
      this.currentPage = selectedPage;
      await this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- project container --------------------
.project-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-size});
}

// -- project grid -------------------------
.project-grid {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem 0;
  gap: 1rem;
  flex-grow: 1;

  @media (min-width: $small-bet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $medium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: $container-size) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// -- pagination nav -----------------------
nav.pagination-nav {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
}
</style>

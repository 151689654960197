<template>
  <article class="card" @click="$router.push(`/projects/${project.id}`)">
    <!-- -- header ------------------------- -->
    <header class="card__header">
      <img :src="headerSource" />
    </header>

    <!-- -- body --------------------------- -->
    <section class="card__body">
      <span class="status">{{ project.status }}</span>
      <h1 class="title">{{ project.city }}, {{ project.adres_line }}</h1>
      <hr />
      <p class="short">{{ project.short }}</p>
    </section>
  </article>
</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    project: {
      type: Object,
      default: function() {
        return {};
      }
    },
    headerSource: {
      type: String,
      default: "/sluis/1.jpeg"
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

// -- card ---------------------------------
.card {
  width: 100%;
  overflow: hidden;
  border-radius: $radius;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.3s ease-out;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
  }
}

// -- image header -------------------------
.card__header {
  img {
    width: 100%;
    height: auto;
  }
}

// -- body ---------------------------------
.card__body {
  padding: 0.25rem 1rem;

  span.status {
    color: $primary;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.7rem;
  }

  h1.title {
    margin: unset;
    font-weight: 400;
  }

  hr {
    width: 80%;
    margin: 1rem auto;
    border-top: 1px solid darken($grey, 10%);
  }

  p.short {
    margin: unset;
    padding: 0 1rem 1rem 1rem;
    font-size: 0.8rem;
    color: $text-accent;
  }
}
</style>
